import merge from 'lodash/merge';
import { baseConfig } from 'merchants/identance';
import { imagesYellow } from 'merchants/yellow/images';
import { IConfig } from 'merchants/types/IConfig';
import * as merchant from 'utils/merchant';
import { themeYellow } from './theme';
import { i18n } from './locales'

const { backUrl, workingMode, product = '' } = merchant.getSettings();

const customConfig = {
  backUrl,
  workingMode,
  product,
  requestToken: merchant.getToken(),
  features: {
    breadcrumbsInHeader: true,
    breadcrumbs: false,
    exitBtn: true,
  },
  i18n,
  images: imagesYellow,
  theme: {
    name: 'yellow',
    config: themeYellow
  },
};

export const config: IConfig  = merge({}, baseConfig, customConfig);
