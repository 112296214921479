import merge from 'lodash/merge'
import { IBaseInputLikeStyledTheme, IInputLikeStyledTheme } from 'merchants/types/IThemeStyles';
import { baseTheme } from 'merchants/identance';
import { palette } from './palette';

const defaultGlobalBaseInputLikeStyles: IBaseInputLikeStyledTheme = {
  backgroundColor: palette.c25,
  borderColor: palette.c8,

  disabled: {
    borderColor: palette.c8,
    backgroundColor: palette.c26,
  },
};

const defaultGlobalInputLikeStyles: IInputLikeStyledTheme = {
  ...defaultGlobalBaseInputLikeStyles,
  borderRadius: '0px',
  borderWidth: '1px',
  boxShadow: 'none',
  color: palette.c1,

  focus: {
    backgroundColor: palette.c25,
    borderColor: palette.c23,
  },

  disabled: {
    ...defaultGlobalBaseInputLikeStyles.disabled,
    color: palette.c12,
  },

  error: {
    backgroundColor: palette.c25,
    borderColor: palette.c14,
  },
};

export const theme = {
  layout: {
    backgroundColor: palette.c3
  },
  buttons: {
    base: {
      borderRadius: '0px',
      backgroundColor: palette.c3,
      border: `1px solid ${palette.c22}`,
      color: palette.c1,
      hover: {
        color: palette.c3,
        backgroundColor: palette.c1,
      }
    },
    primary: {
      backgroundColor: palette.c21,
      borderRadius: '0px',
      border: `1px solid ${palette.c21}`,

      hover: {
        backgroundColor: palette.c3,
        color: palette.c21,
      }
    },
    resubmit: {
      triggerColor: palette.c3,
      color: palette.c1,
      borderColor: palette.c1
    }
  },
  links: {
    base: {
      color: palette.c21,
      hover: {
        color: palette.c21,
      },
    },
    exit: {
      backgroundColor: palette.c3,
      color: palette.c1,
      hover: {
        color: palette.c3,
        backgroundColor: palette.c1,
      }
    },
    nav: {
      color: palette.c3,
      borderRadius: '0px',
      borderColor: palette.c17,
      borderWidth: '1px',
      backgroundColor: palette.c1,
      hover: {
        backgroundColor: palette.c1,
        color: palette.c3
      }
    },
  },
  forms: {
    dropdown: {
      ...defaultGlobalInputLikeStyles,
      placeholderColor: palette.c27,
      hover: {
        backgroundColor: palette.c19
      },
      active: {
        backgroundColor: palette.c18
      }
    },
    input: {
      ...defaultGlobalInputLikeStyles
    },
    radio: {
      ...defaultGlobalBaseInputLikeStyles,
      borderWidth: '1px',

      checked: {
        backgroundColor: palette.c13,
        borderColor: palette.c1,
        backgroundColorAfter: palette.c4,
      },
    },
    textarea: {
      ...defaultGlobalInputLikeStyles
    },
    tumbler: {
      boxShadow: '0 1px 8px 0 #e5e5e5',
      borderWidth: '1px',
      borderColor: palette.c1,
      backgroundColor: palette.c1,

      checked: {
        borderColor: palette.c4,
        backgroundColor:palette.c4,

      }
    }
  },
  text: {
    badge: {
      color: palette.c3,
      backgroundColor: palette.c11,
      danger: {
        backgroundColor: palette.c5,
      },
      primary: {
        backgroundColor: palette.c7,
      },
      warning: {
        backgroundColor: palette.c11,
      },
      success: {
        backgroundColor: palette.c2,
      },
    },
    error: {
      paddingTop: '2px',
      paddingLeft: '0px'
    },
    h1: {
      color: palette.c1,
    },
    h3: {
      color: palette.c1,
    },
    h4: {
      color: palette.c24,
    },
    description: {
      color: palette.c16
    }
  },
  components: {
    appendControl: {
      color: palette.c2
    },
    breadcrumbs: {
      color: palette.c1,
      darkArrow: true
    },
    divider: {
      backgroundColor: palette.c3,
      lineColor: palette.c6,
    },
    feedback: {
      textColor: palette.c1
    },
    modal: {
      colorCloseButton: palette.c3,
      backgroundColorCloseButton: palette.c1,
    },
    modalPreview: {
      colorCloseButton: palette.c3,
      backgroundColorCloseButton: palette.c1,
    },
    navigation: {
      backgroundColor: palette.c3,
      boxShadow: '0 2px 17px 0 #e5e5e5'
    },
    previewArea: {
      borderRadius: '0px',
    },
    progress: {
      backgroundColor: palette.c10,
      backgroundColorAfter: palette.c20,
      tagColor: palette.c1,
      tagFontWeight: 'normal'
    },
    slider: {
      backgroundColorActive: palette.c4,
      backgroundColor: palette.c6,
    },
    stagePreview: {
      borderRadius: '0px',
      titleColor: palette.c1,
    },
    statusBox: {
      background: {
        primary: palette.c4,
        danger: palette.c5,
        warning: palette.c11,
        success: palette.c2,
      }
    },
    uploader: {
      boxShadow: '0 1px 6px 0px rgba(147,147,147,0.2)',
      borderColorLabel: palette.c10,
      backgroundColor: palette.c3,
      borderRadius: '0px',
      borderRadiusLabel: '12px',
      error: {
        borderColor: palette.c14,
      },
    },
  },
  icons: {
    docs: {
      backSide: {
        fill: palette.c13,
        fill2: palette.c13
      },
      blankStatement: {
        fill: palette.c6
      },
      doc: {
        fill: palette.c6,
        fill2: palette.c1
      },
      electricityBill: {
        fill: palette.c6
      },
      frontSide: {
        fill: palette.c13,
        fill2: palette.c17
      },
      miningActivity: {
        fill: palette.c6
      },
      selfie: {
        fill: palette.c17,
        fill2: palette.c6
      },
      selfieWithNote: {
        fill: palette.c17,
        fill2: palette.c6
      },
      taxes: {
        fill: palette.c6,
        fill2: palette.c1
      },
      tradingActivity: {
        fill: palette.c6
      },
      utilityBill: {
        fill: palette.c6,
        fill2: palette.c10,
        fill3: palette.c19
      },
    },
    markup: {
      angle: {
        fill: palette.c1
      },
      arrow: {
        fill: palette.c23
      },
      check: {
        fill: palette.c3
      },
      clip: {
        fill: palette.c15
      },
      clock: {
        fill: palette.c9
      },
      close: {
        fill: palette.c5,
        fill2: palette.c3
      },
      discuss: {
        fill: palette.c1
      },
      exclamation: {
        fill: palette.c3
      },
      exit: {
        fill: palette.c1
      },
      eye: {
        fill: palette.c21
      },
      info: {
        fill: palette.c21,
        fill2: palette.c3
      },
      lock: {
        fill: palette.c9
      },
      ok: {
        fill: palette.c9
      },
      refresh: {
        fill: palette.c25
      },
      star: {
        def: palette.c9,
        active: palette.c4
      },
      stop: {
        fill: palette.c9
      }
    },
    stubs: {
      apiError: {
        fill: palette.c17,
        fill2: palette.c5
      },
      card: {
        fill: palette.c17,
        fill2: palette.c5
      },
      checkEmail: {
        fill: palette.c17,
        fill2: palette.c20
      },
      clock: {
        fill: palette.c17,
        fill2: palette.c5
      },
      corporate: {
        fill: palette.c17,
        fill2: palette.c17
      },
      error: {
        fill: palette.c17,
        fill2: palette.c5
      },
      ok: {
        fill: palette.c17,
        fill2: palette.c2
      },
      notice: {
        fill: palette.c17,
        fill2: palette.c20
      },
      qrConnected: {
        fill: palette.c17,
        fill2: palette.c2
      },
      qrSubmitted: {
        fill: palette.c17,
        fill2: palette.c2
      },
      rejected: {
        fill: palette.c17,
        fill2: palette.c5
      },
      settings: {
        fill: palette.c17,
        fill2: palette.c20
      },
      stop: {
        fill: palette.c17,
        fill2: palette.c5
      }
    }
  }
};

export const themeYellow = merge({}, baseTheme, theme);
