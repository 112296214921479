import merge from 'lodash/merge'
import stageAddressImage from './stages/address.png';
import stageCorporateImage from './stages/corporate.png';
import stageEnhancedImage from './stages/enhanced.png';
import stageIdentityImage from './stages/identity.png';
import mobileAppQR from './mobile-app-qr.png';
import { images as defaultImages } from '../../identance/images'
import logo from './logo.svg';

export const imagesYellow = merge({}, defaultImages, {
  stages: {
    identity: stageIdentityImage,
    address: stageAddressImage,
    enhanced: stageEnhancedImage,
    corporate: stageCorporateImage,
  },
  logo,
  mobileAppQR
});

