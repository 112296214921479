export const palette: { [key: string]: string } = {
    c1: '#090909',
    c2: '#0abf64',
    c3: '#fff',
    c4: '#f0be3f',
    c5: '#e65069',
    c6: '#e1e1e1',
    c7: '#04bcc6',
    c8: 'transparent',
    c9: '#D0D0D0',
    c10: '#e3e7ee',
    c11: '#b36f00',
    c12: '#BEBEBE',
    c13: '#7d7d7d14',
    c14: '#bd3039',
    c15: '#09090914',
    c16: '#0909097a',
    c17: '#C4C4C4',
    c18: '#FDF8EB',
    c19: '#FCF4DF',
    c20: '#FCD000',
    c21: '#ED9220',
    c22: '#A6A6A6',
    c23: '#676767',
    c24: '#4E4E4E',
    c25: '#F5F5F5',
    c26: '#EFEFEF',
    c27: '#848484',
};
